.pulse-login-page {
  display: grid;
  min-height: 100vh;
  grid-template-columns: auto auto;
}

.pulse-signup-verification {
  background: linear-gradient(326.34deg, #4293f4 0%, #13c26b 100%);
  background-position: right;
  overflow-x: hidden;
  background-repeat: no-repeat;
  background-size: 50% auto;
}

.pulse-login-form-wrapper {
  display: flex;
  align-items: center;
  background: linear-gradient(326.34deg, #4293f4 0%, #13c26b 100%);
  background-repeat: no-repeat;
  background-size: calc(100% - 192px) auto;
  background-position: right;
}

.pulse-signup-form-padding {
  padding: 20px 56px 20px;
}

.pulse-login-form-padding {
  padding: 76px 56px;
}

.pulse-auth-form {
  width: 384px;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 2px 8px 0 rgba(7, 16, 26, 0.15);
  font-family: Roboto, sans-serif;

  .pulse-auth-logo,
  .pulse-auth-message {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .pulse-auth-logo {
    margin-bottom: 10px;
    > img {
      height: '42px';
      width: '242px';
    }
  }

  .pulse-auth-message {
    height: 24px;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    color: rgba(7, 16, 26, 0.7);
    margin-bottom: 24px;
  }
}

@media (max-width: 800px) {
  .pulse-login-page {
    grid-auto-rows: max-content;
    grid-template-columns: none;
    grid-template-areas:
      'pulse-login-form-wrapper'
      'pulse-info-text-wrapper';
  }
  .pulse-login-form-wrapper {
    grid-area: pulse-login-form-wrapper;
    display: flex;
    justify-content: center;
    justify-self: center;
    background: none;
    padding-bottom: 46px;
  }

  .pulse-auth-form {
    width: 384px;
    .pulse-signup-form-padding {
      padding: 56px 46px 10px;
    }
    .pulse-login-form-padding {
      padding: 56px 46px;
    }
    .pulse-auth-logo {
      margin-bottom: 8px;
      > img {
        height: 34px;
        width: 192px;
      }
    }
    .pulse-auth-message {
      font-size: 13px;
      margin-bottom: 13px;
    }
  }
}
