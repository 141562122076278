.candidate-reject-reason-popover-content {
  .selected-reject-reasons-wrapper {
    padding: 12px 20px 4px 20px;

    .selected-reasons-title {
      margin-bottom: 8px;
      height: fit-content;
      color: rgba(7, 16, 26, 0.9);
      font-size: 14px;
      letter-spacing: 0;
      line-height: 22px;
    }

    .selected-reject-reasons {
      max-height: 120px;
      overflow: auto;
    }

    .selected-reasons-empty-state {
      margin-bottom: 8px;
      height: fit-content;
      color: rgba(7, 16, 26, 0.3);
      font-size: 14px;
      letter-spacing: 0;
      line-height: 22px;
    }

    .selected-reject-reason-tag {
      height: 22px;
      border: 1px solid #dadbdd;
      border-radius: 11px;
      background-color: #f2f3f3;
      display: inline-flex;
      align-items: center;
      margin-bottom: 8px;
    }
  }

  .more-suitable-reasons-wrapper {
    padding: 12px 20px 12px 20px;
    background-color: rgba(7, 16, 26, 0.03);

    .more-suitable-reasons-title {
      margin-bottom: 8px;
      height: 22px;
      color: rgba(7, 16, 26, 0.9);
      font-size: 14px;
      letter-spacing: 0;
      line-height: 22px;
    }

    .more-suitable-reject-reason-tag {
      height: 22px;
      border: 1px solid #dadbdd;
      border-radius: 11px;
      background-color: #ffffff;
      display: inline-flex;
      align-items: center;
      margin-bottom: 8px;

      .anticon-plus {
        margin-right: 5px;

        > svg {
          height: 8px;
          width: 8px;
        }
      }
    }
  }
}

.tioc-mismatch-content {
  .ant-form-item-control {
    line-height: 0px;
  }
}

.reject-reasons-suggestions-loading-state-wrapper {
  display: flex;
  justify-content: space-between;

  .ant-skeleton-content {
    display: flex;
    justify-content: space-between;
    width: 340px;

    .ant-skeleton-title {
      margin: 0px;
      width: 100px !important;
    }

    .ant-skeleton-paragraph {
      display: flex;
      margin: 0px;
      width: 220px;
      justify-content: space-between;

      > li {
        width: 100px !important;
        margin-top: 0px;
      }
    }
  }
}
