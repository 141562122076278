.signup-verfication-container {
  padding: 50px 0px;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .signup-verification {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 540px;
    height: 540px;
    margin: auto;
    border-radius: 5px;
    box-shadow: 0 0 30px rgba(16, 14, 23, 0.25);
    background: white;
    padding: 30px 100px 30px;
    text-align: center;
  }
}

.minimal-verification-container {
  .signup-verification {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: auto;
    background: white;
    text-align: center;
    padding: 20px;
    border-radius: 5px;
  }
}

.verification-title {
  height: 40px;
  color: #374251;
  font-family: Roboto, sans-serif;
  font-size: 28px;
  letter-spacing: 0.8px;
  line-height: 40px;
}

.verification-info-title {
  height: 24px;
  color: rgba(7, 16, 26, 0.9);
  font-family: Roboto, sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
}

.verification-info {
  color: rgba(7, 16, 26, 0.7);
  font-size: 16px;
  display: block;
  overflow-wrap: anywhere;
  margin-bottom: 0px;
}

.verification-code-resend {
  font-size: 14px;
  margin-top: 20px;
  display: inline-grid;
  grid-template-columns: auto auto;
  justify-items: center;
  align-items: center;
  grid-column-gap: 5px;
}

.resend-button {
  padding: 0px;
}

@media (max-width: 800px) {
  .signup-verfication-container {
    .signup-verification {
      width: 347px;
      height: auto;
      padding: 26px 39px;
    }
  }

  .verification-title {
    font-size: 22px;
  }

  .verification-info-title {
    font-size: 16px;
  }

  .verification-info {
    font-size: 13px;
  }

  .verification-code-resend {
    font-size: 12px;
  }
}
