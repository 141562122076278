.candidate-drawer-header-view {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-right: 50px;
  gap: 15px;
}
.candidate-prev-button,
.candidate-next-button {
  height: 32px;
  width: 72px;
  border-radius: 50px;
  border: none;
  background-color: transparent;
  box-shadow: none !important;
  .candidate-drawer-prev-icon {
    font-size: 13px;
    margin-right: 4px;
    color: #07101a;
  }
  .candidate-drawer-next-icon {
    font-size: 13px;
    margin: 4px;
    color: #07101a;
  }
}

.candidate-next-button:hover,
.candidate-prev-button:hover {
  background-color: #f4f6f8;
}
.minimized-candidate-header-info {
  display: flex;
  flex: 1;
  gap: 20px;
}
.extend-icon {
  cursor: pointer;
  color: #51575e;
  font-size: 13px;
}
.candidate-detail-candidate-past,
.candidate-detail-candidate-edu {
  margin-left: 21px;
  font-family: 'Roboto', sans-serif;
  font-size: 10px;
  display: inline-block;
}

.candidate-detail-candidate-edu-detail {
  display: inline-block;
  font-family: 'Roboto', sans-serif;
  color: #374251;
  font-size: 12px;
  margin-top: 6px;
}

.candidate-detail-candidate-past-detail {
  display: inline-block;
  font-size: 12px;
  font-family: 'Roboto', sans-serif;
  color: #374251;
}

.tab-media-icon.anticon {
  font-size: 24px;
  color: #c8d1db;
}

.tab-media-icon.active {
  color: var(--arya-accent-color);
}

.master-profile-drawer {
  overflow: clip;
}

.master-profile-drawer,
.candidate-detail-full-view {
  .master-profile-tab-content {
    height: calc(100vh - 242px);
    overflow-y: auto;
    background-color: #fafafb;
  }

  .master-profile-tab-content-details {
    height: calc(100vh - 242px);
    overflow-y: auto;
  }

  .master-profile-tab-content-details-without-tabs {
    height: calc(100vh - 131px);
    overflow-y: auto;
    padding-top: 20px;
  }

  .ant-drawer-content-wrapper {
    /* NOTE: This is a hack because 'antd' is adding inline width to drawer*/
    width: 80% !important;
    min-width: 650px !important;
  }

  .ant-drawer-wrapper-body {
    /* NOTE: This is a hack because 'antd' is changing height*/
    height: 100vh !important;
    overflow-y: hidden; // to remove scroll bar
  }
  .ant-drawer-header {
    .ant-drawer-close {
      height: 0;
      line-height: 46px;
    }
  }
}

.candidate-profile-without-banner {
  padding-top: 20px;
}

.profile-personality-notes {
  display: flex;
  justify-content: center;
  align-items: center;

  .ant-radio-button-wrapper {
    align-content: flex-start;

    .candidate-detail-notes-btn-text {
      line-height: 30px;
    }

    .candidate-detail-radio-button {
      display: flex;
      align-items: center;
      height: 30px;
      overflow-y: hidden;

      .ant-skeleton-paragraph {
        max-height: 16px;
        width: 59px;
        margin: 0px;
      }
    }
  }
}

.candidate-view-candidate-detail-header {
  .candidate-view-candidate-reject {
    height: calc(100% + 50px);
  }
  .candidate-shortlist-reject-without-connect-options {
    height: 120px;
  }
  position: relative;
}

.candidate-detail-header-without-connect-options {
  position: relative;
  border-bottom: 1px solid rgba(7, 16, 26, 0.15);
}

.candidate-details-drawer-tabs {
  .ant-badge-dot {
    background-color: #13c26b;
  }
  .ant-tabs-bar {
    padding-left: 24px;
  }
}

.candidate-detail-full-view {
  min-height: calc(100vh - var(--navbar-height));
  border-left: 1px solid rgba(7, 16, 26, 0.15);
  border-right: 1px solid rgba(7, 16, 26, 0.15);
}

.candidate-intel-badge .ant-badge-count {
  background-color: var(--arya-accent-color);
  margin-left: 8px;
}

.candidate-banner {
  margin-bottom: 36px;
}

.sample-candidate-tabs-alignment {
  padding-top: 20px;
}

.candidate-drawer-header {
  .candidate-drawer-header-view-new-tab {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 15px;
    border: 1px solid #e8e8e8;
    border-top: none;
    border-left: none;
    border-right: none;
    background: #fff;
    z-index: 1;
    position: relative;
    .minimized-candidate-header-info-new-tab {
      display: flex;
      flex: 1;
      gap: 20px;
    }
  }
  .candidate-drawer-header-pad {
    padding: 8px 50px 4px 23px;
    border: 1px solid #e8e8e8;
  }
}
