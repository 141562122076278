.call-history-item {
  width: 100%;
  border: 1px solid #ffffff;
  border-radius: 4px;
  background-color: #ffffff;
  padding: 12px;
  margin-bottom: 10px;
  display: flex;

  > div {
    display: flex;
    align-items: center;
  }

  .icon {
    box-sizing: border-box;
    height: 15px;
    width: 15px;
    border-radius: 15px;
    background-color: #ffffff;
    margin-top: 4px;
  }

  .icon.red {
    border: 2px solid #f5222d;
  }

  .icon.green {
    border: 2px solid #52c41a;
  }

  .icon.grey {
    border: 2px solid grey;
  }

  .call-history-content {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin-left: 12px;
  }

  .call-history-with,
  .call-history-status {
    display: flex;
    width: 100%;
  }

  .call-history-with {
    margin-bottom: 4px;

    .phone-number {
      color: #83878c;
      font-family: Roboto, sans-serif;
      font-size: 14px;
      line-height: 22px;
    }

    .time {
      color: rgba(7, 16, 26, 0.5);
      font-family: Roboto, sans-serif;
      font-size: 12px;
      line-height: 16px;
      margin-left: auto;
      margin-right: 0px;
    }
  }

  .call-history-status {
    .call-status {
      color: rgba(7, 16, 26, 0.7);
      font-family: Roboto, sans-serif;
      font-size: 14px;
      line-height: 16px;
    }

    .from {
      color: rgba(7, 16, 26, 0.7);
      font-family: Roboto, sans-serif;
      font-size: 12px;
      line-height: 16px;
      margin-left: auto;
      margin-right: 0px;
    }
  }
}

.call-history-item:hover {
  border: 1px solid #dadbdd;
}
