.dice {
  .dice-advanced-options {
    padding-left: 18px;
    padding-right: 14px;
    margin-bottom: 70px;

    .ant-form-item {
      margin-bottom: 10px;

      .ant-form-item-label {
        line-height: 22px;
      }

      .ant-form-item-control {
        line-height: 1;
      }

      .ant-form-item-label label {
        font-size: 14px;
      }
    }

    .skills-options {
      .skills-exp-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .skill-form-item.ant-form-item,
        .exp-form-item.ant-fom-item {
          margin-bottom: 8px;
        }

        .skills-input {
          width: 181px;
        }

        .exp-inputnumber {
          width: 92px;
        }
      }

      .add-skills-button {
        border-style: dashed;
        border-radius: 20px;
        margin-bottom: 8px;
      }
    }

    .dice-advance-options-select,
    .dice-advance-options-input {
      width: 281px;
      min-height: 32px;
      border-radius: 4px;
    }

    .switch-form-item {
      display: flex;
      align-content: space-between;
      justify-items: center;

      .ant-form-item-control-wrapper {
        width: 44px;
      }
    }

    .salary-form-item-label {
      font-weight: 500;
      font-size: 14px;
      margin-bottom: 6px;

      .salary-type-select {
        width: 80px;
        height: 20px;
        font-size: 12px;

        .ant-select-selection--single {
          height: 20px;
        }

        .ant-select-selection__rendered {
          margin-left: 8px;
          line-height: 20px;
        }
      }
    }

    .relocation-radio-options {
      .relocation-option {
        margin-bottom: 8px;
      }
    }

    .salary-form-item {
      display: 'flex';
      justify-content: 'space-between';
    }

    .salary-type-select {
      width: 70px;
    }

    .dice-maximum-salary,
    .dice-minimum-salary {
      width: 98px;
    }
  }

  .popover-select {
    z-index: 2200;
  }
}
