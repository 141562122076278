.titleSelection {
  border-bottom: 1px solid #e8e8e8;
  padding-bottom: 20px;
  :global .ant-form-item-control {
    line-height: 0px;
  }
}

.titleTagsContainer {
  margin-top: 20px;
}

.titleTag {
  height: 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  border-radius: 3px;
  margin-bottom: 8px;
  margin-right: 8px;
  padding: 2px 4px;
  cursor: pointer;
  &.selected {
    color: #21c574;
    border: 1px solid #21c574;
    font-weight: 500;
  }
  &.unselected {
    color: #808489;
    border: 1px solid #83878c;
    font-weight: 500;
  }
}

.titleLabel {
  color: rgba(7, 16, 26, 0.7);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}
.titleClearAll {
  display: flex;
  .clearAll {
    margin-left: auto;
  }
}
