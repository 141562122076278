.resumeDownloadButton {
  position: absolute;
  align-content: center;
  line-height: normal;
}

.resumeUnlockButtonWrapper {
  margin-left: auto;
  margin-right: 10px;
  .resumeUnlockButton {
    height: 24px;
    background-color: #0d99ff;
    color: #fff;
    font-size: 14px;
    line-height: 22px;
    border-color: #0d99ff;
    :focus {
      color: #0d99ff;
      background-color: #fff;
      border-color: #0d99ff;
    }
  }
}
