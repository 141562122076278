.connect-call-window {
  margin: auto;
  height: 100%;
  padding: 0 20px;

  .user-alert-wrapper {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-150px, -60px);
  }

  .title {
    color: rgba(7, 16, 26, 0.9);
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    margin-top: 15px;
  }

  .connect-call-window-flex-items.phone-dropdown {
    display: flex;
    padding: 0px;
    margin-top: 12px;
    height: 40px;

    button.call {
      width: 77.5px;
      margin-left: 14px;
    }

    .dialpadButton {
      width: 109px;
      margin-left: auto;
      margin-right: 20px;
      font-size: 14px;
      line-height: 22px;
      display: flex;
      align-items: center;
    }

    .ant-select-selection {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }
  }
}

.consent-status-info-message {
  padding: 8px 16px;
  font-size: 14px;
  line-height: 22px;
  border: 1px solid #91d5ff;
  background-color: #e6f7ff;
  border-radius: 4px;
  width: 100%;
  justify-content: start;
  margin: 0 auto;

  .anticon {
    margin: auto 10px;
    color: #1890ff;
  }

  &.consent-pending {
    background-color: #fffbe6;
    border-color: #ffe58f;

    .anticon {
      color: #faad14;
    }
  }

  &.consent-denied {
    background-color: #fff1f0;
    border-color: #ffa39e;

    .anticon {
      color: #f5222d;
    }
  }
}

.invalid-spam-phone,
.invalid-spam-email {
  color: var(--color-invalid-spam);
}
