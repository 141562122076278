.button-group {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.bot-screening-questions {
  margin-top: 40px;
}

.select-template {
  width: 100%;
  margin-top: 20px;
}

.info-gathering-section {
  margin-top: 40px;
}

.bot-responses {
  .question-group {
    margin-bottom: 12px;

    .questions-list {
      border: 1px solid #dadbdd;
      border-radius: 4px;
      padding: 10px;

      .response-item {
        margin-bottom: 10px;

        .question {
          color: rgba(7, 16, 26, 0.7);
          font-family: Roboto, sans-serif;
          font-size: 14px;
          font-weight: 500;
          line-height: 22px;
        }

        .answer {
          border: none;
          background: transparent;
          width: 400px;
        }
      }
    }
  }
}

.bot-config-footer {
  position: absolute;
  display: flex;
  justify-content: space-between;
  left: 0px;
  bottom: 0px;
  width: 100%;
  border-top: 1px solid #e9e9e9;
  padding: 10px 16px;
  background: #fff;
  text-align: right;
}

.bot-config-job-description {
  max-height: 280px;
  overflow-y: scroll;
}
