.inputWrapperChatGpt {
  border: 1px solid #dadbdd;
  background-color: #ffffff;
  padding: 10px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  :global {
    .ql-editor {
      height: 66px;
      overflow: auto;
      padding: 0px;
    }
    .ql-editor.ql-blank {
      font-size: 13px;
      line-height: 18px;
      color: #b4b7ba;
      opacity: 0.8;
      font-weight: 400;
    }
    .ql-editor.ql-blank::before {
      margin-left: -12px;
      font-style: normal;
    }
  }

  .chatGptInputButton {
    :global .ql-container.ql-snow {
      border: none;
    }
  }
  .chatGptInputButton:focus {
    border: none !important;
  }
  .regenerateButton {
    font-size: 22px;
    color: #ffffff;
    padding-left: 10px;
  }
  .regenerateDisableButton {
    font-size: 22px;
    color: rgba(0, 0, 0, 0.1);
    cursor: not-allowed;
    padding-left: 10px;
  }
}

.chatGptButtonInputLabelOnGenerate {
  margin-top: 30px;
  :global .ant-input-search-button {
    padding: 20px;
  }

  :global .ant-input-group {
    > .ant-input:first-child {
      padding: 20px;
    }
  }
}

.suggestionsDropdown {
  max-height: 120px;
  overflow: auto;
  width: 100%;
  z-index: 3200;
  background-color: #ffffff;
  border: 1px solid #dadbdd;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  .liSelected {
    background-color: #f2f3f3 !important;
  }
  :global .ant-dropdown-menu-item:hover {
    background-color: #f2f3f3;
  }
}

.suggestionDropdownItem {
  font-size: 14px !important;
  font-weight: 500 !important;
  white-space: normal;
}
.suggestionsDropdownHeader {
  color: #1f2730;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 3px;
  margin-left: 10px;
}
