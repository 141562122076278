.connect-mail-form {
  height: 100%;
}
.consent-for-chatgpt {
  width: 100%;
  .input-header-content-modal {
    margin: 10px 0px 5px 0px;
    .dynamic-input-header-content {
      color: #1f2730;
      font-size: 14px;
      font-weight: 500;
    }
  }
  .dynamic-input-header-content-modal {
    display: flex;
    margin-top: 10px;
    justify-content: space-between;
    align-items: center;
    .dynamic-input-header-content {
      color: #1f2730;
      font-size: 14px;
      font-weight: 500;
    }
    .restart-button-and-info-icon {
      display: flex;
      align-items: center;
    }
    .restart-button {
      padding: 0px;
    }
    .restart-button-content {
      text-decoration: underline;
    }
  }
  .disclaimer-modal {
    margin-top: 10px;
    .disclaimer-modal-label {
      color: #51575e;
      font-weight: 600;
    }
  }
}

#dynamic-input-header-tooltip {
  padding-left: 2px;
}

.restart-tooltip {
  .ant-tooltip-inner {
    background-color: #1f2730;
  }
  .ant-tooltip-arrow {
    right: 1px;
  }
}

#connect-mail-window {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #fafafb;
  padding: 0px 20px;
  margin-left: 10px;
  overflow-y: scroll;

  .user-alert-wrapper {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-150px, -60px);
  }

  .connect-email-preview {
    overflow: auto;
    border: 1px solid rgba(7, 16, 26, 0.15);
    background-color: #ffffff;
  }
}

.connect-email-dropdown-wrapper {
  width: 400px;
  height: 32px;
  margin-bottom: 6px;

  .connect-email-dropdown {
    width: 340px;

    .ant-select-selection {
      border-left-width: 1px;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
    }
  }
}

.mails-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.inner-mails-wrapper {
  display: flex;
  flex-direction: column-reverse;
  flex-grow: 1;
}
.inner-mails-wrapper-360 {
  flex-direction: column;
}

.email-window-header {
  display: flex;
  align-items: center;
}

.footer-buttons {
  position: absolute;
  bottom: 10px;
  .generate-AI-buttons {
    background-color: #f6ffed;
    margin-left: 12px;
    border-radius: 8px;
  }
}

.connect-mail-window-flex-items {
  padding: 6px 0 5px;
  .email-body-360 {
    width: 100%;
    .rich-text-editor {
      display: flex;
      flex-direction: row-reverse;
    }
  }

  &.mail-dropdown {
    padding-top: 6px;
  }

  .select-to-dropdown {
    width: 240px;
  }

  .rich-text-editor {
    width: 100%;
  }

  .ql-toolbar {
    border: 1px solid #e0e0e0;
    border-bottom: 0px;
    border-radius: 4px 4px 0px 0px;
    background: #fff;
  }
}

.connect-mail-window-flex-items-candidate-view {
  padding: 0px;
}

.connect-mail-bubble-wrapper {
  max-width: 60%;
  margin-bottom: 10px;
}

.connect-mail-bubble-wrapper-right {
  align-self: flex-end;
}

.connect-mail-bubble-wrapper-left {
  align-self: flex-start;
}

.compose-email-icon {
  font-size: 15px;
}

.compose-email-button {
  font-weight: 500;
  padding: 5px 15px;
  font-size: 14px;
}

.composer-close-icon {
  margin-left: 15px;
}

.select-template-wrapper {
  display: flex;
  flex-direction: column;
}

.connect-mail-window-alert {
  margin-bottom: 10px;
}

.connect-mail-from-dropdown-wrapper {
  display: flex;
  align-items: center;
}

.compose-email-button-wrapper {
  border: 1px solid #dadbdd;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  height: 50px;
  background-color: #ffffff;
  margin-top: 12px;
  padding: 7px 0px;
}

.connect-mail-bubble {
  padding: 10px 15px;
  margin-top: 10px;
  // max-width: 60%;
  font-size: 14px;
}

.connect-mail-bubble-right {
  background-color: var(--arya-accent-color);
  color: #ffffff;
  border-radius: 14px 14px 0 14px;
  // align-self: flex-end;
}

.connect-mail-bubble-left {
  background-color: #ffffff;
  border-radius: 0 14px 14px 14px;
  color: rgba(7, 16, 26, 0.7);
  border: 1px solid #dadbdd;
  // align-self: flex-start;
}

.connect-mail-bubble-time {
  margin-left: 10px;
  font-size: 12px;
}

.connect-mail-bubble-time-right {
  text-align: right;
}

.connect-mail-bubble-time-left {
  text-align: left;
}

.connect-email-subject,
.connect-email-preHeader {
  :global {
    .ant-form-item-label {
      height: 25px;
    }
  }
  width: calc(100% - 56px);
}

.connect-email-preHeader {
  position: relative;
  width: calc(100% - 10px);
}

.connect-email-input-wrapper {
  .connect-mail-timeline-wrapper {
    position: relative;
    left: -16px;
  }

  &.connect-mail-without-timeline {
    .ant-timeline-item-tail,
    .ant-timeline-item-head {
      visibility: hidden;
      height: 0px;
    }
    .generative-ai-context-modal {
      width: 100%;
      .existing-template-button {
        padding: 0px;
        .existing-template-button-content {
          text-decoration: underline;
        }
      }
      .generative-ai-context {
        display: flex;
        justify-content: space-between;
        align-items: end;
        border: 1px solid #dadbdd;
        padding-bottom: 9px;
        background-color: #eeeeef;
        .generative-ai-content {
          color: #1f2730;
          margin: 10px 0px 0px 10px;
        }
      }
    }
  }
}

.regenerative-varients-tooltip {
  width: 100px;
}

.connect-mail-timeline-wrapper {
  .ant-timeline-item-tail {
    height: 280px;
  }

  &.ant-timeline-item {
    padding: 0px;
  }

  &.disabled {
    .connect-email-subject {
      margin-top: 15px;
    }
  }
}

.emails-dropdown-select-wrapper {
  display: flex;
  flex-direction: row;
  align-content: center;

  .emails-dropdown-label {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    margin-top: 1px;
    padding-left: 11px;
    align-self: center;
    padding-top: 5px;
    width: 60px;
    height: 32px;
    border: 1px solid #d9d9d9;
  }

  .ant-select-selection {
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
    border-left: 0px;
  }
}
.candidate-360-select-from {
  display: flex;
  align-items: center;
  color: #374251;
  .candidate-email-from {
    font-weight: 500;
    color: #1f2730;
  }
}
.connect-email-dropdown {
  border-bottom-left-radius: 0px;
}

#connect-aryacredit-icon {
  margin-left: auto;
  margin-right: 5px;
  width: 20px;
  height: 20px;
}

#mail-input-box-wrap {
  display: flex;
  // margin-bottom: 30px;
  align-items: flex-end;

  // min-height: 40px;
  .email-composer-preview {
    overflow: auto;
    border: 1px solid rgba(7, 16, 26, 0.15);
    background-color: #ffffff;
    max-height: 500px;
  }
}

.candidate360EmailPopUpHead {
  .email-composer-preview {
    overflow: scroll;
    border: 1px solid #dadbdd;
    max-height: 408px;
  }
}

#mail360-input-box-wrap {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

.connect-mail-window-drips-info {
  padding: 0 10px 10px 0px;
  height: 30px;
  cursor: pointer;

  & > span {
    border-radius: 12px;
    border: 1px solid #dadbdd;
    background-color: #f2f3f3;
    padding: 5px;
    font-size: 12px;
    line-height: 20px;
  }

  margin-top: 5px;
}

#connect-mail-send-button {
  // align-self: flex-end;
  // height: 40px;
  margin-left: 10px;
}

.connect-mail-window-flex-items > .ant-collapse > .ant-collapse-item > .ant-collapse-content,
.connect-mail-window-flex-items > .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  background-color: #fafafb;
}

// this css is use to remove ant default color for this div only

.connect-mail-window-flex-items > .ant-collapse {
  border-left: none;
  border-right: none;
  display: flex;
  flex-direction: column-reverse;
}

.email-to-subject {
  display: flex;
  position: relative;
  align-items: center;
}

.email-to,
.email-from {
  height: 20px;
  max-width: 40%;
  color: #374251;
  font-size: 14px;
  line-height: 20px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.email-subject,
.email-subject-360 {
  height: 22px;
  max-width: 50%;
  color: rgba(7, 16, 26, 0.7);
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.email-subject-360 {
  color: #51575e;
}

.email-preHeader,
.email-preHeader-360 {
  height: 22px;
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: 500;
  color: #6b7075;
}
.candidate-360-view-panel-header {
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.subject-and-collapse-icon {
  display: flex;
  justify-content: space-between;
}
.candidate-360-mail-collapse-icon {
  position: absolute;
  right: 20px;
  top: 20px;
}
.email-subject-and-email-to-container {
  display: flex;
  flex-direction: column;
  gap: 6px;
  color: #51575e;
}
.candidate-360-email-collapse {
  background-color: white;
}
.candidate-360-collapse-header {
  .ant-collapse-content {
    border-top: none;
  }
}
.email-stack-group {
  padding: 4px 0px;

  .email-view {
    width: 100%;
    overflow: auto;
    div {
      white-space: normal !important;
    }

    p {
      margin-bottom: 0px;
    }

    .ql-size-small {
      font-size: 10px !important;
    }
    .ql-size-medium {
      font-size: 13px !important;
    }
    .ql-size-large {
      font-size: 20px !important;
    }
  }
}

.email-reply-icon {
  height: 28px;
  width: 28px;
  border-radius: 50%;
  margin-left: auto;

  &:hover {
    background-color: #dddddd;
  }
}
.email-meta-reply-icon {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  .email-reply-icon-360 {
    height: 28px;
    margin-left: auto;
  }
  .email-meta {
    display: flex;
    align-items: flex-end;
    color: #83878c;
    flex: 1;
    justify-content: space-around;
    margin-right: 20px;
    height: 28px;
  }
}

.email-meta {
  display: flex;
  align-items: center;
  color: #83878c;
}

.email-status .active {
  color: var(--arya-accent-color);
}

.email-status .danger {
  color: var(--arya-error-color);
}

.email-status .clock {
  color: #faad14;
}

.email-status-360 .active {
  display: flex;
  gap: 4px;
  align-items: center;
  color: var(--arya-accent-color);
}

.email-status-360 .danger {
  color: var(--arya-error-color);
}

.email-status-360 .clock {
  color: #faad14;
}
.email-status-360 {
  display: flex;
  gap: 4px;
  align-items: center;
  color: #51575e;
}
.email-time {
  line-height: 16px;
  font-size: 12px;
  color: rgba(7, 16, 26, 0.5);
  margin-left: auto;
}

.mail-editor {
  width: 100%;

  .ql-editor {
    min-height: 100px;
    max-height: calc(100vh - 570px);
    background-color: #ffffff;
  }

  min-height: 100px;
  max-height: calc(100vh - 570px);
  background-color: #ffffff;
  border-radius: 0px 0px 4px 4px;
}

.mail-window-merge-tag {
  cursor: pointer;
  margin: 5px 0px 0px 13px;
  color: var(--arya-accent-color);
  width: 90px;
  .mergeTagDisabled {
    cursor: not-allowed;
  }
}

.preheader-with-subject {
  position: absolute;
  z-index: 1;
  border-left: 1px solid;
}

.mail-window-merge-tag-preHeader {
  position: absolute;
  margin: 0;
  z-index: 1;
  top: 8px;
  width: 100px;
  left: calc(100% - 20px);
  cursor: pointer;
}

.disabled {
  color: grey;
}

.mail-subject-editor {
  width: 100%;

  .ql-editor {
    height: 42px;
    width: 100%;
    padding-right: 90px;
    overflow: hidden;
    background-color: #ffffff;
  }

  .ql-container {
    border-radius: 4px;
  }

  .ql-editor.ql-blank {
    font-size: 14px;
    line-height: 24px;
    color: #b4b7ba;
  }
}

.connect-email-subject-360 {
  height: 55px;
  border: 1px solid #dadbdd;
  background-color: #fff;
}
.preheader-with-subject {
  left: calc(100% - 43px);
  position: absolute;
  border-left: 1px solid #dadbdd;
  height: 52px;
}

.mail-subject-editor-360 {
  width: 100%;
  .ql-editor {
    height: 32px;
    background-color: transparent;
    padding-right: 90px;
    overflow: hidden;
    width: 100%;
    border-bottom: 1px solid #e8e8e8;
    margin-top: 5px;
  }

  .ql-container {
    border: none;
  }

  .ql-editor.ql-blank {
    font-size: 14px;
    line-height: 10px;
    color: #b4b7ba;
  }
}

.custom-mail-subject-editor-360 {
  width: 93%;
  .ql-editor {
    min-height: 42px;
    max-height: 42px;
    padding-right: 90px;
    overflow: auto;
    width: calc(100% - 45px);
    margin-top: 5px;
    background-color: #fff !important;
  }

  .ql-container {
    border: none;
  }

  .ql-editor.ql-blank {
    font-size: 14px;
    line-height: 10px;
    color: #b4b7ba;
  }
}

.mail-merge-tags-componser-close {
  display: flex;
  margin-left: auto;
  align-items: baseline;
}

#templates-select {
  width: 400px;
  margin-bottom: 20px;
}

.template-label {
  font-size: 13px;
  font-weight: 400;
  color: #005ce6;
  margin-bottom: 5px;
}

.email-templates-dropdown [role='option'] {
  display: flex;
  font-size: 14px;
  line-height: 22px;
  max-height: 200px;
  overflow: hidden;

  .email-template-name {
    margin-right: auto;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.invalid-spam-phone,
.invalid-spam-email {
  color: var(--color-invalid-spam);
}

.connect-drip-condition {
  display: flex;

  .ant-select {
    width: 142px;
  }

  > div {
    margin-right: 10px;
  }
}

.extended-connect-mail-window-360 {
  margin-bottom: 60px;
}
.email-option-container {
  display: flex;
  gap: 10px;
  .add-email {
    color: #13c26b;
  }
}
#connect-mail-window-360 {
  display: flex;
  flex-direction: column;
  padding-right: 40px;
  margin-right: 20px;
  margin-left: 10px;
  .user-alert-wrapper {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-150px, -60px);
  }
  .connect-email-preview {
    overflow: auto;
    border: 1px solid rgba(7, 16, 26, 0.15);
    background-color: #ffffff;
  }
  .email-panel-container {
    display: flex;
    flex-direction: column;
    gap: 28px;
    .rich-text-editor {
      display: flex;
      flex-direction: column-reverse;
      .custom-toolbar {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        border: none !important;
        border-bottom: 1px solid #ccc !important;
        .custom-toolbar-header {
          flex: 1;
        }
      }
    }
    .mail-editor {
      .ql-container {
        border: none !important;
      }
    }
  }
  .candidate-reply-email-header {
    display: flex;
    gap: 20px;
    .collapse-and-send-button-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: space-between;
    }
  }
  .emailPanel {
    flex: 1;
    overflow-x: hidden;
    .select-to-dropdown-360,
    .select-tempalete-candidate-360,
    .connect-email-dropdown-360 {
      .ant-select-selection,
      .ant-select-selection:hover,
      .ant-select-selection:focus {
        background-color: #ffffff !important;
        box-shadow: none !important;
      }
    }
    .ql-editor {
      background-color: #ffffff !important;
    }
    .mail-window-merge-tag-360 {
      top: auto !important;
      left: calc(100% - 210px);
    }
  }
}
.modal-content-360 {
  .connect-mail-timeline-wrapper {
    .ant-timeline-item-tail {
      height: 350px;
    }
  }
  display: flex;
  flex-direction: column;
  padding-right: 20px;
  max-height: calc(80vh) !important;
  overflow-y: auto;
  overflow-x: hidden;
  .select-to-dropdown-360,
  .select-tempalete-candidate-360,
  .connect-email-dropdown-360 {
    color: #374251;
    .ant-select-selection,
    .ant-select-selection:hover,
    .ant-select-selection:focus {
      border: none !important;
      background-color: transparent;
      box-shadow: none;
      min-width: 120px;
      width: fit-content;
      max-width: 400px;
    }
    .ant-select-arrow {
      color: #1f2730;
      right: 0px;
    }
    .ant-select-selection__clear {
      right: 0px;
    }
  }
  .connect-email-dropdown-360 {
    width: 460px;
  }
  .candidate-360-select-from,
  .email-window-header,
  .select-tempalete-candidate-360 {
    border-bottom: 1px solid #e8e8e8;
    margin-bottom: 4px;
  }
  .consent-status-info-message {
    padding: 4px;
  }
  .email-window-header {
    justify-content: space-between;
  }
  .select-tempalete-candidate-360 {
    width: 100%;
    color: #374251;
    .ant-select-selection__placeholder {
      color: #374251;
    }
    .ant-select-selection__rendered {
      margin-left: 0px;
    }
    .ant-select-selection,
    .ant-select-selection,
    .ant-select-selection:hover,
    .ant-select-selection:focus {
      min-width: fit-content !important;
      max-width: max-content;
    }
  }

  #templates-select {
    width: 100%;
    margin-bottom: 10px !important;
  }
  .candidate-360-to-email-wrapper {
    display: flex;
    color: #374251;
    line-height: 1;
    .candidate-email-to {
      margin-top: 16px;
      font-weight: 500;
      color: #1f2730;
    }
  }
  .connect-email-subject {
    :global {
      .ant-form-item-label {
        height: 25px;
      }
    }
    width: 100%;
  }
  .mail-editor {
    width: 100%;
    max-height: 100%;
    .ql-editor {
      min-height: 25vh;
      background-color: #ffffff;
      border-radius: 0px !important;
    }
    .ql-container {
      border-bottom: none;
    }
  }

  .disabled-icon {
    cursor: not-allowed !important;
    color: #b4b7ba;
  }

  .alert-banner {
    width: 100%;
    height: 47vh;
    z-index: 2223;
    background-color: #ffffff;
    position: absolute;
    border: 1px solid #ccc;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    .alert-banner-descrition {
      display: flex;
      flex-direction: column;
      .banner-description-tag2 {
        margin-left: 55px;
      }
      .banner-description-tag3 {
        margin-left: 95px;
      }
    }
    .ant-alert-description {
      color: #83878c;
      font-size: 18px;
      margin-top: 90px;
      margin-left: 28.5%;
      width: 50%;
    }
  }
  .chatGpt-mail-editor {
    width: 100%;
    max-height: 100%;
    .ql-editor {
      min-height: 37.6vh;
      max-height: 37.6vh;
      background-color: #ffffff;
      border-radius: 0px !important;
      padding-bottom: 50px;
      overflow: auto;
    }
    .ql-container {
      background-color: white;
    }
  }

  .mail-editor-360 {
    width: 100%;
    max-height: 100%;
    .ql-editor {
      min-height: 45vh;
      max-height: 45vh;
      background-color: #ffffff;
      border-radius: 0px !important;
      overflow: auto;
    }
  }

  .rich-text-editor {
    display: flex;
    flex-direction: column-reverse;
    .ql-toolbar {
      .ql-mergetags {
        width: auto;
        color: var(--arya-accent-color);
      }
    }
    .rich-text-editor-true {
      width: 100%;
    }
    .custom-toolbar {
      display: flex;
      flex-direction: row-reverse;
      border-top: none !important;
      border-radius: 0px !important;
      .custom-toolbar-header {
        flex: 1;
      }
    }
  }
  .mail-window-display-drip-template {
    width: 100% !important;
    .emailsubject {
      .ql-container {
        margin-bottom: 0 !important;
      }
    }
    .rich-text-editor {
      .custom-toolbar {
        border-top: 1px solid #ccc !important;
      }
    }
    .rich-text-editor {
      .ql-toolbar {
        .ql-mergetags {
          float: left;
          position: inherit;
        }
      }
    }
  }
  .empty-select-template {
    .ant-select-selection,
    .ant-select-selection,
    .ant-select-selection:hover,
    .ant-select-selection:focus {
      min-width: 140px !important;
    }
  }
}

.candidate-view-email-label-section {
  display: flex;
  align-items: center;
  gap: 7px;

  #labelMergeTag {
    color: #13c26b;
    text-decoration: underline;
  }
}

.candidate-view-label-font {
  font-weight: 500;
  color: #1f2730;
}

.candidate-view-preheader {
  width: 100%;
  max-width: calc(100% - 55px);
  .ql-editor {
    height: 42px;
    width: 100%;
    padding-right: 90px;
    overflow: hidden;
    background-color: #ffffff;
  }

  .ql-container {
    border-radius: 4px;
  }

  .ql-editor.ql-blank {
    font-size: 14px;
    line-height: 24px;
    color: #b4b7ba;
  }
}

#candidateViewAddPreHeaderText {
  color: #13c26b;
  text-decoration: underline;
}

#candidateViewAddPreHeaderButton {
  padding: 5px 0px 5px 0px;
}

#candidateView360AddPreHeaderButton {
  padding-left: 10px;
}
#candidateView360AddPreHeaderButtonDisabled {
  cursor: not-allowed;
  padding-left: 10px;
  margin-top: 27px;
}

#candidateViewPreHeaderRemoveSection {
  position: relative;
  left: calc(100% + 10px);
  bottom: 30px;
}

#candidateView360PreHeaderRemoveSection {
  position: relative;
  left: calc(100% + 4px);
  bottom: 25px;
}

#candidateViewPreHeaderRemoveIcon {
  font-size: 18px;
}

.candidateViewSubjectMergeTags {
  position: absolute;
  z-index: 5;
  left: calc(100% - 164px);
  top: 30px;
  cursor: pointer;

  span {
    text-decoration: underline;
    color: #13c26b;
  }
}

.regenerateButton-varients {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}
.candidateViewPreHeaderMergeTags {
  position: absolute;
  z-index: 5;
  cursor: pointer;
  left: calc(100% - 150px);
  top: 100px;

  span {
    color: #13c26b;
    text-decoration: underline;
  }
}

.email-stack-card-details {
  display: flex;
  max-width: 100%;
  align-items: center;
  flex-wrap: wrap;
}

.candidate-view-email-body-label {
  margin-top: 3px;
}

.candidate-view-email-subject-label {
  display: flex;
  gap: 7px;
  align-items: center;

  #labelMergeTag {
    text-decoration: underline;
    color: #13c26b;
  }
}

.subject-container {
  display: flex;
  align-items: baseline;
  height: 100%;

  .subject-label {
    min-width: 60px;
    padding-left: 10px;
  }
}

.candidate360-compose-email {
  font-weight: 300;
  .candidate360-compose-email-manually {
    font-weight: 500;
  }
}

.compose-email-chatGPT {
  display: flex;
  .candidate360-compose-email {
    font-weight: 300;
    margin-left: 4px;
    .candidate360-compose-email-chatGPT {
      font-weight: 500;
    }
  }
}
