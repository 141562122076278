.candidate360Message {
  background: #ffffff;
  border: 1px solid #dadbdd;
  border-radius: 4px;
  padding: 6px 20px 2px 20px;
  margin: 10px 0;
  position: relative;
  cursor: pointer;
  .candidateJobInfo {
    p {
      margin-bottom: 0;
      color: #51575e;
      font-weight: 600;
    }
  }

  .contentContainer {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .phoneNumber {
      margin-top: 8px;
      margin-bottom: 7px;
      color: #1f2730;
      font-weight: 400;
    }
    small {
      color: #83878c;
      font-size: 10px;
      position: absolute;
      right: 20px;
      top: 9px;
    }
    .message {
      color: #51575e;
      margin-bottom: 0;
    }
    .status {
      font-style: italic;
      font-size: 12px;
    }
  }
}
