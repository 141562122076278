.portals {
  padding-top: 20px;
  .sourceTitle {
    font-size: 16px;
    font-weight: 500;
  }
  .portalActivationButtons {
    max-height: 210px;
    overflow-y: scroll;
  }
}
