.candidateShortlistContainer {
  display: flex;
  gap: 28px;
  align-items: flex-start;
  cursor: pointer;
  i {
    font-size: 32px;
    > svg {
      color: gray;
    }
  }
  .candidateTransferIcon {
    svg {
      color: white !important;
    }
  }
  .shorlistIconDiv,
  .candidateTransferIconDiv,
  .rejectIconDiv {
    flex: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .shorlistIconDiv {
    .shortlistedIcon {
      svg {
        color: #13c26b;
      }
    }
  }
}
.shortlistContainerWithActionWidgets {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  align-self: stretch;
}
