.filtergroupWrapper {
  width: 90%;
  margin-left: 15px;
  .filterHeader {
    display: flex;
    .filterGroup {
      border: 1px solid #dadbdd;
      border-radius: 5px;
      padding: 10px;
      width: 100%;
    }
    .closeIcon {
      display: flex;
      justify-content: end;
      width: auto;
    }
  }
}

.datePicker {
  width: 250px;
}
.datePickerDropdown {
  width: 200px;
  z-index: 2200;
}
.emailDripStyle {
  display: flex;
  justify-content: space-between;
}

.emptyText {
  color: #1f2730;
}
.campaignStatsPanel {
  :global {
    .ant-collapse .ant-collapse-item-disabled > .ant-collapse-header {
      color: #1f2730;
    }
  }
  :global .ant-timeline-item {
    .ant-timeline-item-content {
      padding-top: 10px;
      top: 0px;
    }
  }

  :global .ant-timeline-item-last {
    padding: 0px;
    .ant-timeline-item-content {
      min-height: 0px;
    }
  }
}

.fieldValuesTooltip {
  max-width: 500px;
  display: block;
}

.campaignStats {
  display: flex;
  align-items: center;
  gap: 5px;
  .campaignType {
    border: 1px solid #f2f3f3;
    width: 50px;
    padding-left: 8px;
    border-radius: 10px;
    background-color: #f2f3f3;
  }
  .campaignAttributes {
    color: #1f2730;
    font-size: 14px;
    font-weight: 500;
  }
}
.campaignStatsHeader {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.filterGroupsAggregationSpliter {
  margin: 10px 0px 10px 0px;
  color: #83878c;
  margin-left: 43%;
  font-size: 20px;
}

.filterItemsAggregationSpliter {
  margin: 10px 0px 10px 0px;
  border: 1px solid #f5f5f5;
  background-color: #f5f5f5;
  width: 40px;
  padding-left: 5px;
}
.advanceFiltersWrapper {
  .advanceFilters {
    :global .ant-row {
      display: flex;
      gap: 5px;
    }
    .closeIcon {
      color: #ff7875;
    }
  }
}

.locationText {
  color: #ff7875;
  margin-left: 23%;
}
.inputNumberErrortext {
  color: #ff7875;
  margin-left: 15%;
}
.addFilterButton {
  display: flex;
  align-items: center;
  gap: 5px;
  width: 92px;
  cursor: pointer;
  .addIcon {
    color: #69c0ff;
  }
}

.addFilterItemButton {
  display: flex;
  align-items: center;
  gap: 5px;
  width: 50px;
  cursor: pointer;
  .addIcon {
    color: #69c0ff;
  }
}

.candidateFilterWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
  .filterGroupHeader {
    margin-bottom: 10px;
    .filterGroupHeaderLabel {
      color: #1f2730;
      margin-left: 15px;
    }
  }
  .filterGroupHeaderBorder {
    border: 1px solid #dadbdd;
    width: 90%;
    margin-left: 15px;
  }
}

.filterFieldsDropDown {
  :global .ant-select-dropdown-menu {
    max-height: 20vh;
  }
}

.timelineItem {
  border: 1px solid #e8e8e8;
  width: 90%;
  margin-top: 15px;
}

.hiddenTimeline {
  height: 0;
  :global {
    .ant-timeline-item {
      padding: 0;
    }
  }
}
