.candidate-intel-not-found {
  text-align: center;
  margin-top: 225px;
}

.overview,
.skill-proximity,
.role-similarity,
.occupational-similarity,
.education-relevance,
.industry-relevance {
  margin: 24px;
  display: flex;
}

.overview-icon,
.skill-proximity-icon,
.role-similarity-icon,
.industry-relevance-icon,
.education-relevance-icon,
.occupational-similarity-icon {
  border-radius: 4px;
  text-align: center;
  margin-right: 16px;
  padding: 12px;
  height: 48px;

  i {
    margin: auto;
    font-size: 24px;
  }
}

.overview-icon {
  background-color: var(--arya-accent-color);
}

.skill-proximity-icon,
.role-similarity-icon,
.industry-relevance-icon,
.education-relevance-icon,
.occupational-similarity-icon {
  background-color: rgba(7, 16, 26, 0.05);
}

.proximity-icon > svg {
  fill: var(--arya-accent-color);
}

.intel-header {
  display: flex;
  position: relative;
}

.intel-heading {
  color: rgba(7, 16, 26, 0.9);
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  margin-bottom: 0px;
}

.intel-subheading {
  color: rgba(7, 16, 26, 0.7);
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 22px;
}

.intel-description {
  color: rgba(7, 16, 26, 0.9);
  font-size: 14px;
  letter-spacing: 0;
  line-height: 22px;
  margin-bottom: 8px;
}

.candidate-intel-alert {
  border: none;
  .ant-alert-message {
    color: rgba(7, 16, 26, 0.85);
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
  }

  .antd-alert-description {
    color: rgba(7, 16, 26, 0.65);
    font-size: 14px;
    letter-spacing: 0;
    line-height: 22px;
  }
}

.empty-candidate-intel-icon {
  box-sizing: border-box;
  height: 58px;
  width: 58px;
  border: 1px solid #dadbdd;
  background-color: #ffffff;
  border-radius: 50%;
  margin: auto;
  padding: auto;

  i {
    margin-top: 14px;
    font-size: 24px;
  }
}

.intel-score {
  position: absolute;
  right: 0;
}

.line-break {
  box-sizing: border-box;
  height: 1px;
  width: 100%;
  border: 1px solid #dadbdd;
}

.candidate-score-card-group {
  height: 168px;
  width: 280px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.candidate-intel-overview-details {
  display: flex;
  flex-wrap: wrap;
}

.overall-score-heading {
  color: rgba(7, 16, 26, 0.9);
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 22px;
}

.intel-labels {
  display: flex;
  flex-wrap: wrap;
}

.intel-label-group {
  display: flex;
  flex-wrap: wrap;
  margin-right: 16px;
  margin-bottom: 16px;
}

.intel-label-color {
  height: 10px;
  width: 10px;
  border-radius: 5px;
  margin: 2px 8px 0px 0px;
}

.candidate-label {
  background-color: #5b8ff9;
}

.job-label {
  background-color: var(--arya-accent-color);
}

.others-label {
  background-color: rgba(7, 16, 26, 0.2);
}

.intel-label-text {
  color: rgba(7, 16, 26, 0.7);
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;
}
