.candidateListItemContainer {
  padding: 10px;
  border-bottom: 1px solid #cfd5db;
  min-height: 75px;
  cursor: pointer;
}

.isCandidateCardActive {
  border-left: 5px solid #01cd75;
}

.candidateNameSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  width: 100%;

  .candidateName {
    font-weight: 500;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: baseline;
    gap: 5px;
    line-height: 22px;
    flex: 3;
  }

  .conversationTime {
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: end;
    color: #7f8fa5;
    letter-spacing: 0.45px;
    flex: 1;
  }
}

.candidateChatInfoSection {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .chatDescription {
    max-width: 212px;
    height: auto;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
