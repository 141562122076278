.candidateAggregation {
  width: 750px;
  :global {
    .ant-tabs {
      .ant-tabs-left-bar {
        width: 250px;
        .ant-tabs-tab {
          text-align: left;
          margin: 0;
        }
        .ant-tabs-tab-active {
          :local {
            .arrowIcon {
              display: inherit;
            }
          }
          background-color: #f5f5f5;
        }
      }
    }
    .ant-tabs-ink-bar {
      background-color: inherit;
    }
  }
}

.CandidateFilterAggregationTitle {
  display: flex;
  align-items: center;
  gap: 10px;
}

.arrowIcon {
  display: none;
}

.tabNameWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.filterContent {
  padding-top: 10px;
  max-height: 350px;
  overflow-y: auto;
}

.filterContent::-webkit-scrollbar {
  width: 5px;
  background-color: #ffffff;
}

.filterContent::-webkit-scrollbar-thumb {
  border-radius: 3.5px;
  background: #d8d8d8;
}

.favouriteFilter {
  h3 {
    padding: 0px !important;
  }
}
