.locationSection {
  margin-top: 25px;
  .locationTabs {
    margin-top: 15px;
  }
  .locationLabel {
    font-size: 16px;
    font-weight: 500;
  }
  :global {
    .ant-select-selection,
    .ant-select-focused,
    .ant-input,
    .ant-input-number {
      border: none !important;
      border-bottom: 1px solid gainsboro !important;
      border-radius: 0px;
      box-shadow: none !important;
    }
    .ant-select-selection__rendered {
      margin-left: 4px !important;
    }
    .ant-input,
    .ant-input-number-input {
      padding-left: 4px;
    }
  }
}

.minimizedLocationSection {
  width: 560px !important ;
}
.selectedLocation {
  font-weight: 500;
  color: var(--secondary-black-color);
}
