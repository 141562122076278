.candidate-reject-reasons-popover-overlay {
  width: 420px;
  z-index: 100;

  &.medium {
    z-index: 3000;
  }

  .ant-popover-title {
    display: flex;
    align-items: center;
    height: 40px;
    padding: 0px 0px 0px 17px;
    background-color: rgba(19, 194, 107, 0.2);
    border-bottom: none;
  }
}

.reject-reason-warning-message {
  padding: 11px 22px 0px 22px;
  display: flex;

  .reject-reasons-popover-info-icon {
    margin-right: 8px;
  }
}

.candidate-reject-reasons-popover-footer {
  height: 54px;
  padding-right: 20px;
  align-items: center;
  display: flex;
  justify-content: flex-end;

  .candidate-reject-reasons-footer-buttons {
    width: 110px;
    display: flex;
    justify-content: space-between;

    .ant-btn {
      display: flex;
    }
  }
}

.candidate-reject-reasons-popover-title-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .candidate-reject-reasons-popover-title-intel-icon {
    margin-right: 12px;
    display: flex;
    color: var(--arya-accent-color);

    i > svg {
      height: 24px;
      width: 24px;
    }
  }
}
