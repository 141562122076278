.resumeViewerContainer {
  height: calc(100vh - 290px) !important;
  overflow-y: auto;
}

.resumeViewerContainer > div {
  display: flex;
  justify-content: space-around;
}

.resumeViewer {
  width: 85%;
  padding-left: 20px;
  overflow-y: hidden;
  > mark {
    border-radius: 4px;
  }
}

.resumeDownload {
  width: 10%;
}

.extendView {
  height: calc(100vh - 120px) !important;
}

.extendView > div {
  min-height: calc(100vh - 115px) !important;
}
