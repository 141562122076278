.pulse-info-text-wrapper {
  display: grid;
  grid-auto-rows: max-content;
  row-gap: 71px;
  margin: auto 56px auto auto;
  .pulse-info-text {
    display: grid;
    grid-template-columns: 2fr 8fr;
    justify-content: center;
    align-items: center;
  }

  .pulse-info-message-header {
    font-size: 28px;
    font-weight: bold;
    letter-spacing: 0.4px;
    line-height: 25px;
  }

  .pulse-info-message-description {
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
  }

  .first-level-text {
    margin-top: 16px;
  }

  .second-level-text {
    margin-top: 8px;
  }
}

@media (max-width: 800px) {
  .pulse-info-text-wrapper {
    grid-area: pulse-info-text-wrapper;
    row-gap: 40px;
    margin: auto;
    .pulse-info-message-header {
      font-size: 18px;
    }
    .pulse-info-message-description {
      font-size: 12px;
    }
    .second-level-text {
      margin-top: 2px;
    }
    .first-level-text {
      margin-top: 5px;
    }
  }
  .pulse-info-image > img {
    height: 39px;
    width: 48px;
  }

  .pulse-info-message-header {
    font-size: 18px;
  }

  .pulse-info-message-description {
    font-size: 10px;
  }
}
