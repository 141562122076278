.contacts {
  height: calc(100vh - 280px);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.extendView {
  height: calc(100vh - 120px);
  overflow-y: auto;
}

.extendView > * {
  min-height: calc(100vh - 115px);
}
.candidateContactInfo {
  border-bottom: 2px black;
}
.candidateContactsWrapper {
  .composeButtonWrapper {
    :global .ant-affix {
      background-color: white;
    }
    .composeButton {
      display: flex;
      border-top: 1px solid #dadbdd;
      padding: 12px 20px 20px 20px;
      justify-content: flex-end;
    }
  }
}
