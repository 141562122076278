.label {
  font-size: 16px;
  font-weight: 500;
  line-height: 39px;
  padding-left: 8px;
}

.multiLocationItem {
  margin-bottom: 18px;
  display: flex;
  align-items: center;
}
