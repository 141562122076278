.selectAndButtonWrapper {
  display: flex;
  gap: 14px;
  margin-bottom: 28px;
}

.workflowSelect {
  width: 384px;
}
.linkButtons {
  color: #13c26b;
  align-items: center;

  :global {
    .ant-btn {
      padding: 0 5px;
    }
    .ant-btn-link {
      color: #13c26b;
    }
  }

  span {
    text-decoration: underline;
  }
}
.startEndDate {
  display: flex;
  gap: 17px;
  margin-bottom: 28px;
}
.triggeredEndTimeSelect {
  display: flex;
  align-items: center;
  gap: 17px;
}
.datePicker {
  width: 200px;
}
.datePickerDropdown {
  width: 200px;
  z-index: 2200;
}
.sendToContainer {
  display: flex;
  margin-top: 14px;
  margin-bottom: 4px;

  .sendToLabel {
    font-weight: 500;
    margin-right: 10px;
  }
}
