.secondaryCardHeader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 53px;
  border-bottom: 1px solid #cfd5db;
  background-color: #fff;
  width: 100%;
  padding: 10px;

  .headerTitle {
    display: flex;
    align-items: center;
    font-size: 17px;
    font-family: Roboto, sans-serif;
    font-weight: 700;
    letter-spacing: 0.77px;
    line-height: 20px;
    height: 32px;
    margin-bottom: 10px;
    color: #374251;
  }

  .botIcon {
    margin-left: 10px;
    margin-top: 3px;
  }

  .search {
    margin-left: 10px;
  }

  .secondary-list-extras {
    margin-left: auto;
    display: flex;
  }
}

.email-stats-filter {
  .ant-badge-dot {
    background: #13c26b;
  }
}

.ant-popover-title {
  .email-stats-filter-title {
    display: flex;
    width: 280px;
    align-items: baseline;
    height: 54px;
    justify-content: space-between;
  }
}

.email-stats-filter-title-text {
  color: rgba(7, 16, 26, 0.9);
  font-size: 16px;
  font-weight: 500;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.email-stats-filter-apply-clear {
  margin-left: auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.apply-button,
.clear-button {
  height: 28px;
  width: auto;
  border-radius: none;
  margin-left: 10px;
  line-height: 22px;
  font-size: 14px;
}

.clear-button {
  border: 1px solid #dadbdd;
  background-color: #ffffff;
}

.apply-button {
  background-color: #13c26b;
  margin-left: 16px;
  border: none;
}

.email-stats-filter-content {
  padding: 8px 0px 16px 16px;
}
