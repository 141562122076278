.template-table-header {
  height: 40px;
  background-color: #f4f6f8;
  color: rgba(7, 16, 26, 0.5);
  font-size: 12px;
  padding: 10px 0px 20px 20px;
  letter-spacing: 0.5px;
  border-radius: 5px;
  font-weight: 500;
  line-height: 16px;
  margin-top: 12px;
}

.ant-row.template {
  background-color: #ffffff;
  padding-left: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e8e8e8;
}

.template-title {
  max-width: 90%;
  color: rgba(7, 16, 26, 0.9);
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.template-subject {
  height: 16px;
  width: 50%;
  color: rgba(7, 16, 26, 0.7);
  font-family: Roboto, sans-serif;
  font-size: 12px;
  line-height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: 6px;
}

.template-column {
  cursor: pointer;
}

.template-updated {
  height: 16px;
  width: 58px;
  color: rgba(7, 16, 26, 0.7);
  font-family: Roboto, sans-serif;
  font-size: 12px;
  line-height: 16px;
}

.template-bot-status {
  height: 16px;
  width: 20px;
  color: rgba(7, 16, 26, 0.7);
  font-family: Roboto, sans-serif;
  font-size: 12px;
  line-height: 16px;
}

.template-shared-count {
  height: 22px;
  width: 8px;
  color: rgba(7, 16, 26, 0.7);
  font-family: Roboto, sans-serif;
  font-size: 14px;
  line-height: 22px;
}

.template-by {
  height: 22px;
  width: 8px;
  color: rgba(7, 16, 26, 0.7);
  font-family: Roboto, sans-serif;
  font-size: 14px;
  line-height: 22px;
}

.clone-button {
  height: 24px;
  width: 100px;
  border: 1px solid #dadbdd;
  background-color: #ffffff;
}
