.login-auth-form {
  .login-auth-header,
  .login-auth-footer-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .login-auth-header {
    color: rgba(7, 16, 26, 0.9);
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
    margin-bottom: 0.25em;
  }

  .login-auth-label {
    height: 24px;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
  }

  .login-auth-label {
    color: rgba(7, 16, 26, 0.9);
  }

  .login-auth-email {
    margin-bottom: 15px;
  }

  .login-auth-password {
    margin-bottom: 12px;
  }

  .login-auth-input {
    width: 100%;
    height: 40px;
    font-size: 16px;
    line-height: 24px;

    .ant-input {
      font-size: 16px;
      line-height: 24px;
    }
  }

  .login-auth-forgot-password {
    height: 22px;
    color: #13c26b;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 22px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .login-auth-forgot-password-btn {
      padding: 0px;
    }
  }

  .login-auth-login-button {
    margin-top: 24px;
    font-size: 16px;
    line-height: 24px;
    width: 100%;
    height: 40px;
  }

  .login-auth-footer-wrapper {
    .login-auth-footer {
      height: 22px;
      font-family: Roboto, sans-serif;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 22px;
      display: flex;
      align-items: center;
      align-content: center;
      text-align: center;
      margin-top: 12px;

      .login-auth-footer-link-wrapper {
        margin-left: 4px;

        .login-auth-footer-link-btn {
          padding: 0px;
        }
      }
    }
  }
}

@media (max-width: 800px) {
  .login-auth-form {
    .login-auth-label {
      font-size: 13px;
    }
    .login-auth-forgot-password-btn,
    .login-auth-footer,
    .login-auth-footer-link-text {
      font-size: 11.2px !important;
    }
    .login-auth-login-button {
      margin-top: 18px;
      font-size: 13px;
    }
  }
}
