.completeHistory {
  display: flex;
  padding: 0 20px 0 20px;
}

.title {
  color: #1f2730;
  font-weight: 600;
  font-size: 14px;
  margin: 10px 0 10px 0;
}

.summary {
  margin-bottom: 8px;
}

.rightAlign {
  height: calc(100vh - 285px) !important;
  overflow-y: auto;
  margin-left: 75px;
  width: 88%;
}

.extendView {
  height: calc(100vh - 115px) !important;
}

.extendView > div {
  min-height: calc(100vh - 110px) !important;
}
