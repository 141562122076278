.call-history {
  height: calc(100% - 55px);

  .list {
    overflow-y: scroll;
    height: calc(100% - 42px);
  }

  .title {
    color: rgba(7, 16, 26, 0.9);
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    margin-bottom: 5px;
  }

  .empty-call-icon {
    width: 40px;
    height: 142px;
    font-size: 33px;
  }
}
