.candidateActivityLogs {
  height: calc(100vh - 285px);
  overflow: auto;
  padding: 10px 20px 0 40px;
  :global .ant-timeline-item-tail {
    border-left-width: 3px;
  }
}

.extendView {
  height: calc(100vh - 115px);
}

.extendView > * {
  min-height: calc(100vh - 110px);
}

.hiddenActivityLog {
  height: 0;
  :global {
    .ant-timeline-item {
      padding: 0;
    }
  }
}

.activityLog {
  border-bottom: 1px solid #e8e8e8;
  padding-bottom: 10px;
}

.timelineItem:nth-last-child(2) {
  .activityLog {
    border-bottom: 0;
  }
  :global {
    .ant-timeline-item {
      .ant-timeline-item-tail {
        height: calc(100% - 45px);
      }
    }
  }
}

.activityLogHeader {
  color: #1f2730;
  font-weight: 500;
  font-size: 14px;
}

.activityLogTitle {
  display: flex;
  align-items: center;
  margin: 15px 0 0 -12px;
}

.activityMarker {
  width: 16px;
  height: 2px;
  background-color: #dadbdd;
}

.titleName {
  color: #13c26b;
  font-weight: 400;
  font-size: 14px;
  margin-left: 8px;
}

.eventIcon {
  margin-left: 5px;
  font-size: 18px;
}

.activityLogBody {
  margin-left: 5px;
}

.mainContent {
  font-weight: 400;
  color: #1f2730;
  font-size: 14px;
}

.secondaryContent {
  font-weight: 400;
  color: rgba(81, 87, 94, 0.9);
  font-size: 14px;
}

.emptyScreen {
  color: rgba(7, 16, 26, 0.9);
  font-size: 20px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
}
