.window-wrapper {
  display: flex;
  height: 90vh;
  width: 100vw;
  justify-content: center;
  align-items: center;

  .login-wait-text {
    color: rgba(7, 16, 26, 0.9);
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    margin-bottom: 10px;
  }
}

.auth-container {
  .app-footer,
  .privacy-and-terms {
    margin: auto;
    margin-top: 25px;
    text-align: center;
  }
  .privacy-and-terms {
    width: 320px;
  }
}

@media (max-width: 800px) {
  .auth-container-pulse {
    padding: 46px 0px;
  }
}
