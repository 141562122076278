.auth-container,
.pulse-auth-container {
  height: 100vh;

  .arya-logo-white-label {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0px auto 40px;
    padding-top: 20px;
  }
}

.pulse-auth-container {
  height: 100vh;
  background: linear-gradient(329.21deg, #4293f4 0%, #1bbd78 100%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.auth-form {
  width: 360px;
  margin: auto;
  padding: 24px 48px;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 2px 8px 0 rgba(7, 16, 26, 0.15);
  font-family: Roboto, sans-serif;

  .auth-title,
  .auth-sub-title {
    text-align: center;
  }

  .auth-title {
    color: rgba(7, 16, 26, 0.9);
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
    margin-bottom: 0.25em;
  }

  .auth-sub-title {
    color: rgba(7, 16, 26, 0.7);
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 2em;
  }

  .user-message {
    font-size: 12px;
    color: var(--arya-accent-color);
  }

  .error-message {
    color: var(--arya-error-color);
    font-size: 12px;
  }

  .auth-label {
    color: rgba(7, 16, 26, 0.7);
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    margin-top: 16px;
    margin-bottom: 0;
  }

  .auth-input {
    width: 100%;
    font-size: 16px;
    line-height: 24px;
    height: 40px;
  }

  .auth-primary-button,
  .auth-secondary-button {
    width: 100%;
  }

  .auth-primary-button {
    margin-top: 24px;
    border-radius: 20px;
    font-size: 16px;
    line-height: 24px;
    height: 40px;
  }

  .auth-secondary-button {
    margin-top: 16px;
    color: #13c26b;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    border-width: 0;
    box-shadow: unset;
  }

  .auth-pulse-verify-button,
  .auth-pulse-back-button {
    min-width: 45%;
    margin-top: 24px;
    border-radius: 20px;
    font-size: 16px;
    line-height: 24px;
    height: 40px;
  }

  .auth-pulse-verify-button {
    float: right;
  }

  .sent-code-info {
    text-align: center;

    p {
      font-size: 16px;
    }
  }

  .pulse-logo {
    display: flex;
    justify-content: center;
    padding: 8px;
    margin-bottom: 32px;

    i > svg {
      height: 42px;
      width: 242px;
    }
  }
}

@media (max-width: 800px) {
  .pulse-auth-container {
    background: none;
  }

  .auth-form {
    .auth-title {
      font-size: 19px;
    }

    .auth-sub-title {
      font-size: 13px;
    }

    .ant-btn {
      font-size: 13px;
    }

    .pulse-logo {
      i > svg {
        height: 34px;
        width: 192px;
      }
    }
  }
}
