.modalTextArea {
  .ant-input {
    height: 100px;
  }
}
.modalContent {
  line-height: 0.7;
}
:global {
  .ant-modal-centered .ant-modal {
    top: 64px;
  }
}
