.locationBox {
  border: 0.8px solid #13c26b;
  border-radius: 24px;
  display: flex;
  align-items: center;
  height: 35px;
  padding: 15px;
  .locationContent {
    margin-right: 11px;
    width: max-content;
  }
  .editIcon {
    border: 0.8px solid #13c26b;
    height: 35px;
    padding: 3px 8px 8px 8px;
    cursor: pointer;
  }
  .disableEditIcon {
    border: 0.8px solid #51575e;
    cursor: not-allowed;
  }
  .close-icon {
    position: absolute;
    color: #919191;
    cursor: pointer;
    font-size: 16px;
    right: 10px;
  }
  .closeIcon {
    padding-left: 5px;
    padding-top: 2px;
    color: #83878c;
    cursor: pointer;
    &.disableCloseIcon {
      cursor: not-allowed;
      color: #51575e;
    }
    &.disableCloseSymbol {
      opacity: 0.4;
      cursor: not-allowed;
    }
  }
}

.disableLocationBox {
  border: 0.8px solid #51575e;
  cursor: not-allowed;
}
