.searchCriteriaSelect {
  :global .ant-select-selection {
    border-radius: 16px;
  }
  :global .ant-select-selection__rendered {
    margin-right: 0px;
  }
}

.searchCriteriaSelectPlaceholder {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.spinLoaderContainer {
  position: relative;
  padding-bottom: 5px;
}
.spinLoader {
  position: relative;
  left: 12px;
}
.filterContainer {
  padding: 19px 60px 19px 24px;
  min-width: 328px;
}

.recentHeading {
  padding-left: 24px;
  padding-top: 8px;
  font-size: 16px;
  margin-bottom: 0.5em;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.85);
}
.agentName {
  font-weight: 700;
  margin-bottom: 0;
  margin-top: 0;
  font-size: 15px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.85);
}
.searchCriteria {
  input {
    border-radius: 22px;
  }
}
.dropdownContainer {
  ul {
    max-height: 300px;
    overflow-y: scroll;
  }
}
.optionContainer {
  padding-left: 11px;
  display: flex;
  gap: 5px;
  align-items: baseline;

  .nameAndIcon {
    display: flex;
    align-items: end;
    gap: 8px;

    .iconDisplay {
      padding-top: 7px;
    }

    h3 {
      margin-bottom: 0;
    }
  }
}

.agentNameAndInfo {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.spinLoaderText {
  position: relative;
  left: 20px;
  color: #13c26b;
}
