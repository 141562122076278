.tagSection {
  :global {
    .ant-tag {
      font-size: 12px;
      border-radius: 18px;
      background-color: #f2f3f3;
      margin: 5px 2px;
      color: #1f2730;
      font-weight: 400;
    }
  }
}
