.workflowDetailsContainer {
  width: 100% !important;
  max-width: 100% !important;
  text-align: left;
  color: rgb(0, 0, 0);

  .backToListWrapper {
    display: flex;
    align-items: center;

    background-color: white;
    .backToListText {
      text-decoration: underline;
    }
  }
}

.popoverContent {
  display: flex;
  width: 174px;
  padding: 10px 30px 10px 10px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-around;
  height: 230px;
}

.state {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 159px;
  height: 31px;
  padding-bottom: 10px;
  cursor: pointer;
}

.redirectionSection {
  position: relative;
  display: flex;
  justify-content: space-between;
}

.workflowDetailsHeader {
  z-index: 1000;
  padding: 8px 0px 0 50px;
  position: fixed;
  width: 97%;
  .workflowDetailsHeaderContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    :global {
      .ant-form-item-control-wrapper {
        height: 32px;
      }
    }
  }

  .closeWorkflowIcon {
    position: absolute;
    right: 15px;
  }
  .workflowDetailsHeaderLeftContent {
    display: flex;
    align-items: center;
    gap: 10px;
    grid-gap: 10px;
    .workflowDetailsHeaderInput {
      display: flex;
      align-items: center;
      gap: 10px;
      > a {
        color: #13c26b;
      }
    }
  }
  .workflowReportNote {
    color: #1890ff;
    background: #e6f7ff;
    border: 1px solid #91d5ff;
    border-radius: 12px;
    font-size: 12px;
    width: fit-content;
    padding: 1px 7px;
    margin-left: 88px;
  }
}
.workflowDetailsHeaderAts{
  width: 90%;
  z-index: 500;
}
