.appliedSourceTag {
  width: max-content;
  padding: 3px 8px;
  background-color: rgba(19, 194, 107, 0.4);
  border-radius: 10px;
  color: rgba(7, 16, 26, 0.9);
  margin-left: 5px;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;
  display: inline-block;
  margin-right: 4px;
}
.candidate360AppliedSourceTag {
  font-size: 12px;
  background-color: #51575e;
  color: #ffffff;
  line-height: normal;
  padding: 6px 24px;
  border-radius: 0;
  border-bottom-right-radius: 10px;
  margin-left: 0px;
}
