.templateColumn {
  cursor: pointer;
}

.draftDesc {
  color: red;
}

:global {
  .ant-badge-status-dot {
    width: 9px;
    height: 9px;
  }
  .ant-badge-status-success {
    background-color: #36cf7d;
  }
}

.deleteOptionText {
  color: #ff4d4f;
}

.template {
  background-color: #fff;
  color: rgb(0, 0, 0);
  font-weight: 400;
  padding-left: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e8e8e8;

  a {
    text-decoration: underline;
    color: #13c26b;
  }
}
