.auto-email-id-container {
  display: flex;
  font-size: 12px;
  line-height: 16px;
  align-items: center;
  // margin-top: 24px;

  .auto-email-icon {
    margin-right: 8px;
    font-size: 13px;
  }

  .auto-email-label-text {
    font-weight: 700;
  }

  .auto-email-id,
  .auto-email-send-to {
    margin-left: 4px;
    font-weight: 500;
  }
}
