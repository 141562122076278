.bulkOperationsCandidateCriteriaWrapper {
  display: flex;
  gap: 5px;
}

.bulkOperationsCandidatesMain {
  padding-top: 10px;
  display: flex;
  flex-direction: column;
}
.bulkOperationsCandidateCriteriaInfoIcon {
  padding-top: 7px;
  font-size: 16px;
  color: #83878c;
}
.bulkOperationsCandidateCriteria {
  font-size: 12px;
  margin-bottom: 0px;
}
