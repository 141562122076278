.partialClass {
  max-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: rgba(7, 16, 26, 0.7);
  font-weight: 400;
}

.jobTitle {
  color: #13c26b;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 8px;
}

.companyName {
  color: rgba(7, 16, 26, 0.9);
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 8px;
}

.timelineItem {
  :global {
    .ant-timeline-item-content {
      border-bottom: 1px solid #e8e8e8;
    }
  }
}

.cardView {
  :global .text-preview-total {
    color: rgba(7, 16, 26, 0.7);
    font-weight: 400;
  }
}

.historySummary {
  margin-bottom: 10px;
}

.timelineItem:last-child {
  :global {
    .ant-timeline-item-tail {
      height: calc(100% - 45px);
    }
    .ant-timeline-item-content {
      border-bottom: 0;
    }
  }
}

.historyTimeline {
  :global .ant-timeline-item-tail {
    border-left-width: 3px;
  }
}

.hiddenTimeline {
  height: 0;
  :global {
    .ant-timeline-item {
      padding: 0;
    }
  }
}

.titleAndDuration {
  display: flex;
  column-gap: 7px;
}
