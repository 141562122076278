.candidateCardTertiaryContent {
  display: flex;
  gap: 14px;
  justify-content: flex-end;
  align-items: center;
  :global {
    .ant-btn-link {
      font-size: 14px;
    }
  }
}
