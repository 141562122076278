.connect-notification {
  display: flex;
  width: 360px;
  justify-content: space-around;
  border-bottom: 1px solid #e6eaee;
  padding: 12px;
  cursor: pointer;

  &:hover {
    background-color: #f3f3f3;
  }

  .icon {
    margin-right: 10px;
  }

  .content {
    width: 280px;
    color: #81878e;

    font-size: 13px;

    .ant-badge {
      width: 100%;
      .ant-badge-dot {
        background-color: #13c26b;
      }
    }

    .sender-name {
      color: rgba(7, 16, 26, 0.9);
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
    }

    .message {
      color: rgba(7, 16, 26, 0.7);
      font-size: 14px;
      line-height: 22px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .since {
      color: rgba(7, 16, 26, 0.5);
      font-size: 12px;
      line-height: 16px;
    }
  }
}
