.pulsePasswordResetContainer {
  height: 100vh;
  background: linear-gradient(329.21deg, #4293f4 0%, #1bbd78 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  .passwordResetWrapper {
    height: 494px;
    width: 462px;
    margin: auto;
    padding: 80px 110px;
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 0 2px 8px 0 rgba(7, 16, 26, 0.15);
    font-family: Roboto, sans-serif;
  }
}

.aryaPasswordResetContainer {
  height: 100vh;
  background: #ffffff;
  align-items: center;
  justify-content: center;
  .passwordResetWrapper {
    height: 330px;
    width: 462px;
    margin: auto;
    padding: 40px 110px;
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 0 2px 8px 0 rgba(7, 16, 26, 0.15);
    font-family: Roboto, sans-serif;
  }
}

.passwordResetWrapper {
  height: 494px;
  width: 462px;
  margin: auto;
  padding: 80px 110px;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 2px 8px 0 rgba(7, 16, 26, 0.15);
  font-family: Roboto, sans-serif;
}

.pulseLogo {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;

  i > svg {
    height: 42px;
    width: 242px;
  }
}

.backToLogin {
  border-radius: 20px;
  font-size: 16px;
  height: 40px;
  width: 162px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 56px;
}

.passwordUpdateText {
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: 226px;
  color: #374251;
  font-family: Roboto, sans-serif;
  font-size: 20px;
  letter-spacing: 0.57px;
  line-height: 24px;
  text-align: center;
  margin-top: 20px;
}

.icon {
  width: 44px;
  margin: auto;
  display: flex;
  .iconSize {
    font-size: 44px;
    svg {
      height: 70px;
    }
  }
}
