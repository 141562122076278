.candidateView {
  overflow: hidden;
  border-left: 1px solid rgba(7, 16, 26, 0.15);
  border-right: 1px solid rgba(7, 16, 26, 0.15);
}

.candidateHeader {
  display: flex;
  min-height: 167px;
  border-bottom: 1px solid #e8e8e8;
  transition: all 0.4s linear;
}

.candidatePrimaryInfo {
  min-width: 30%;
  border-right: 1px solid #e8e8e8;
}

.candidateInfoCard {
  height: 65%;
  padding: 15px 10px 10px 20px;
}

.candidateActions {
  width: 5%;
  .enhancedShortlistContainer {
    flex-direction: column !important;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    gap: 0px;
  }
  .enhancedShortlistContainer > div:not(:first-child) {
    border-top: 1px solid #dadbdd;
  }
  .enhancedShortlistContainer > div {
    padding: 0px 15px;
  }
}

.candidateSecondaryInfo {
  width: 65%;
  border-right: 1px solid #e8e8e8;
  z-index: 0;
}

.candidateTag {
  height: 40%;
}

.candidateConnect {
  display: flex;
  align-items: center;
  margin-top: 11px;
  gap: 14px;
  :global .connect-get-contact {
    margin: 0px;
  }
}

.candidateQuickInfo {
  height: 12%;
}

.hideCandidateHeader {
  visibility: hidden;
  margin-top: -170px;
}

.showDownloadedPortalInfo {
  height: 180px;
}
.latestMarkupDate {
  font-size: 12px;
  font-style: italic;
  color: #83878c;
  margin-top: 8px;
  width: max-content;
}
