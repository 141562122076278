.skillSelection {
  position: relative;

  &.minimize {
    padding-top: 0px;
  }
}
.hideMinimisedView,
.hideSection {
  display: none;
}
.booleanMinimisedView {
  display: none;
}
.booleanMaximizeView {
  display: block;
}

.clearAllButton {
  display: inline-block;
  position: absolute;
  right: 0;
  top: 20px;
  gap: 5px;
  align-items: center;
  text-decoration: underline;
}
.skillsSectionLabel {
  font-size: 13px;
  text-decoration: underline;
  cursor: pointer;
  color: #13c26b;
}
.aryaAssistedSkills {
  margin-top: 5px;
  &.minimize {
    margin-top: 0px;
  }
}
.booleanSkills {
  margin-top: 5px;
}

.expanded {
  padding-top: 20px !important;
  border-bottom: 1px solid #e8e8e8 !important;
  padding-bottom: 25px !important;
}

.skillsLabel {
  color: rgba(7, 16, 26, 0.7);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.skillTag {
  display: inline-flex;
  border-radius: 15px;
  margin-bottom: 8px;
  margin-right: 8px;
  padding-left: 8px;
  padding-right: 4px;
  padding-top: 4px;
  padding-bottom: 4px;
  cursor: pointer;
  &.selected {
    color: #51575e;
    border: 1px solid #83878c;
    font-weight: bold;
    .skillTagItem:not(:last-child) {
      border-right: 0.4px solid #b4b7ba;
    }
  }

  &.unselected {
    color: #83878c;
    border: 1px solid #eaebee;
    .skillTagItem:not(:last-child) {
      border-right: 1px solid #eaebee;
    }
  }

  &.prominent {
    font-weight: bold;
  }

  .skillTagItem {
    display: flex;
    align-items: center;
    padding: 0px 4px;
  }
}

.booleanSkillsText {
  font-size: 13px;
  font-weight: 500;
}

.suggestedSkills {
  margin-top: 15px;
}

.suggestedSkill {
  cursor: pointer;
  border: 1px solid #83878c;
  display: inline-block;
  border-radius: 3px;
  color: #808489;
  padding: 2px 4px;
  font-size: 12px;
  margin-right: 8px;
  margin-bottom: 8px;
}

.booleanOperators {
  display: flex;
  gap: 10px;
}

.booleanOperatorTag {
  cursor: pointer;
  display: inline-block;
  border: 1px solid #91d5ff;
  border-radius: 2px;
  background-color: #e6f7ff;
  padding: 2px 8px;
  color: #1890ff;
}

.queryString {
  margin-top: 15px;
  .booleanStringTextArea {
    min-height: 150px;
  }
}
.booleanMaximizeView {
  display: block;
}

.additionalSkillsContent {
  padding: 10px;
  width: 400px;

  .additionalSkillsHeader {
    font-size: 11px;
    font-weight: 500;
  }

  .skillSuggestionsWrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .aliasSkillsHeader {
      display: flex;
      justify-content: space-between;
      .selectAllCheckbox {
        font-size: 11px;
        :global .ant-checkbox-inner {
          height: 14px;
          width: 14px;
        }
      }
    }
    .skillsBox {
      border: 0.5px solid #979797;
      border-radius: 4px;
      padding: 5px;
      .skillsBoxHeader {
        color: rgba(7, 16, 26, 0.7);
        font-size: 11px;
        font-weight: 500;
      }
      .skillsList {
        display: flex;
        flex-wrap: wrap;
        max-height: 100px;
        overflow-y: scroll;
        margin-top: 5px;
        .additionalSkillTag {
          cursor: pointer;
          display: inline-flex;
          color: #83878c;
          border: 1px solid #abafb4;
          border-radius: 3px;
          font-size: 11px;
          align-items: center;
          padding: 2px;
          margin-right: 5px;
          margin-bottom: 5px;
          &.selected {
            border: 1px solid #83878c;
            color: #83878c;
            font-weight: 500;
          }
          .selectedSkill {
            display: flex;
            align-items: center;
            gap: 5px;
          }
        }
      }
    }
  }
}

.manualSearchPopoverFooter {
  border-top: 1px solid gainsboro;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;
  height: 50px;
  button.clearButton {
    border-radius: 15px;
    padding: 2px 15px;
    color: #51575e;
    font-weight: 400;
    font-size: 15px;
  }
}

.maunalSearchAutoCompleteInput {
  :global {
    .ant-input-group > .ant-input:first-child,
    .ant-input-wrapper > .ant-input:focus {
      border: none !important;
      border-bottom: 1px solid gainsboro !important;
      border-radius: 0px !important;
    }
  }
}

.popoverKeywordSectionContainer {
  padding: 20px 20px 10px 20px;
  max-width: 380px;
  min-width: 380px;
}

.popoverActiveStringToggleButton {
  float: right;
}

.popoverView {
  .aryaAssistedSkills,
  .booleanSkills {
    margin-top: 30px;
  }
}
