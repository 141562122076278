.candidatesTab {
  position: static;
  margin-top: 5px;
}
.scoutingAgentTabCandidatesTab {
  :global {
    .ant-tabs-nav-scroll {
      padding-top: 16px;
    }
  }
}
.cadidateSearchTab {
  :global .ant-tabs {
    position: static !important;
  }
}
.loadingIcon {
  display: flex;
  align-items: center;
  gap: 4px;
  i {
    color: grey;
  }
}
.scoutingAgentWithCount {
  display: flex;
  cursor: pointer;
  gap: 4px;
}
.manualSearchTabs {
  :global {
    .ant-tabs-extra-content {
      line-height: 55px;
    }
  }
  .scoutingAgentTabButton {
    border-radius: 0px;
    border-bottom: 2px solid #13c26b !important;
  }
}
