.titleContainer {
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  h2 {
    color: #1f2730;
    font-size: 16px;
    font-weight: 400;
  }
}

.header {
  color: #1f2730 !important;
}

.emptyText {
  color: #1f2730;
  width: 57%;
  margin: auto;
}

.frquencyDay {
  z-index: 3050;
}

.scoutingAgentTableDisplay {
  :global {
    .ant-table-placeholder {
      border-bottom: none;
    }
  }
}
