.skill-missing-content-wrapper {
  .skill-missing-title-input-wrapper {
    padding: 12px 20px 0px 20px;

    .skill-missing-content-title {
      height: 22px;
      color: rgba(7, 16, 26, 0.9);
      font-size: 14px;
      letter-spacing: 0;
      line-height: 22px;
    }

    .add-missing-skill-input {
      margin-top: 5px;

      .ant-input-wrapper {
        > input {
          font-size: 14px;
        }
      }

      .ant-input-search-button {
        width: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .anticon {
        > svg {
          height: 16px;
          width: 16px;
        }
      }
    }
  }

  .selected-skills-wrapper {
    max-height: 200px;
    overflow: auto;
    padding-left: 20px;
    margin-top: 8px;
    margin-right: 5px;
  }
}

.reject-reasons-skill-suggestions-dropdown {
  min-width: auto !important;
  width: 352px;

  .reject-reasons-skill-suggestions-menu {
    max-height: 150px;
    overflow: auto;
    background-clip: border-box;
  }
}
