.reportingPortalTitle {
  color: rgba(7, 16, 26, 0.9);
  font-family: Roboto, sans-serif;
  font-size: 28px;
  font-weight: 500;
  letter-spacing: 0.4px;
  line-height: 40px;
  margin-bottom: 30px;
}

.reportingPortalComponent {
  :global .ant-tabs-top-bar {
    margin: 0px;
  }
}

.globalInsightContainerWrapper {
  padding-top: 24px;
}
