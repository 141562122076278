.filterContent {
  .filterNote {
    margin-top: 20px;
    h4 {
      margin: 0;
    }
  }
  .filterButton {
    background: inherit;
    border-radius: 0;
    color: #51575e;
    font-weight: 500;
    height: 60px;
    text-align: left;
    padding: 0 13px 6px 13px;
    .overview {
      margin-top: 5px;
    }
    small {
      text-decoration: underline;
      color: var(--arya-accent-color);
    }
  }

  .filterButton:hover,
  .filterButton:active {
    background: inherit;
    color: #51575e;
  }

  .dropDownBody {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 8px;
    .rightContent {
      font-size: 24px;
      color: var(--arya-accent-color);
    }
    .leftContent {
      margin-top: 3px;
    }
    .leftContent > div {
      margin-top: 5px;
    }
  }
}

.contentStyle {
  background: white;
  cursor: pointer;
  transition: background-color 50ms;
}

.parentStyle {
  width: 250px;
  background: white;
}

.parentStyle:hover {
  .contentStyle:hover {
    opacity: 1;
  }
  .contentStyle {
    opacity: 0.5;
  }
}

.activeSegment {
  background: var(--arya-accent-color) !important;
  cursor: pointer;
  transition: 'background-color 50ms';
  color: white;
  .filterButton:hover,
  .filterButton:active,
  .filterButton:focus {
    background: inherit;
    color: white;
  }
  .filterButton {
    color: white;

    small {
      color: white;
    }

    .rightContent {
      color: white;
    }
  }
}

.filterReportMenu {
  padding: 0 !important;
  max-width: 280px;
  white-space: break-spaces;
  :global {
    .ant-checkbox-group {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }
    .ant-checkbox-wrapper {
      width: 100%;
      padding: 9px;
      margin-left: 0;
      display: flex;
      .ant-checkbox {
        padding-top: 4px;
      }
      .ant-checkbox-checked::after {
        border: none;
      }
    }
    .ant-checkbox-wrapper:hover,
    .ant-checkbox-wrapper-checked {
      background-color: #e6ffee;
    }
  }
}

.filterReportDropdown {
  min-width: 200px;
  :global {
    .ant-menu-item-selected,
    .ant-dropdown-menu-item:hover {
      background-color: #fff;
    }
    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
      background-color: #fff !important;
    }
    .ant-menu-root.ant-menu-vertical,
    .ant-menu-root.ant-menu-vertical-left,
    .ant-menu-root.ant-menu-vertical-right,
    .ant-menu-root.ant-menu-inline {
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    }
    .ant-menu-item {
      height: fit-content;
      max-height: 300px;
      overflow: scroll;
    }
  }
}

.triangle {
  margin-top: 0;
  position: relative;
  width: 15px;
}

.triangle > div {
  width: inherit;
}

.triangle > div:before {
  content: ' ';
  display: block;
  width: 7px;
  height: 1px;
  background: #b4b7ba;
  transform: rotate(133deg);
  position: absolute;
  top: -10px;
  right: -33px;
}

.triangle > div:after {
  content: ' ';
  display: block;
  width: 7px;
  height: 1px;
  background: #b4b7ba;
  -webkit-transform: rotate(58deg);
  transform: rotate(48deg);
  position: absolute;
  top: -10px;
  left: 36px;
}

.filterSearchInput {
  max-width: 200px;
  input {
    border-radius: 30px;
    padding-right: 68px !important;
  }
}
