.workflowDetailsFooter {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px 100px;
  gap: 5px;

  .workflowFooterNote {
    margin: auto auto auto 0;
    color: #51575e;
  }
}
