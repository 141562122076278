.reportViewFilterForm {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.reportViewFilterFormItems {
  display: flex;
  gap: 16px;
  .timeRangeDatePicker {
    display: flex;
    align-items: flex-end;
  }
}

.formTimeRangeSelect {
  width: 160px !important;
}

.formItemRecruiters,
.formItemClients,
.formItemJobs {
  width: 50%;
}

.clientSelect,
.recruiterSelect,
.jobSelect {
  :global .ant-select-selection {
    max-height: 60px;
    overflow-y: auto;
  }

  :global .ant-select-selection__choice {
    border-radius: 11px;
    background-color: #f2f3f3;
    border: 1px solid #dadbdd;
  }
}

.dateRangePicker {
  width: 210px;
}

.formDateFormatSelect {
  width: 185px !important;
}

.formDateFormatOption {
  width: 200px !important;
}
